<template>
    <v-sheet border rounded :width="wide ? 359 : 185" height="50" class="d-flex flex-row">
        <v-btn :color="text_color" class="my-auto" icon variant="text" @click="yesterday">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-menu v-model="dialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ props }">
                <v-btn :loading="loading" :color="text_color" class="my-auto" variant="text" :width="wide ? 265 : 110"
                    v-bind="props">
                    {{ date }}
                </v-btn>
            </template>
            <!-- WE USE THIS PARSING ISO DATESTRINGS -->
            <v-date-picker no-title :model-value="adaptedDate" width="360" @update:model-value="touchDate">
                <v-spacer></v-spacer>
            </v-date-picker>
        </v-menu>
        <v-btn :color="text_color" class="my-auto" icon variant="text" @click="tomorrow">
            <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
    </v-sheet>
</template>

<style>
/* Setting padding to 0 prevents hiding last week of the month on mobile devices */
div.v-date-picker-table>table>tbody>tr>td {
    padding: 0 !important;
}
</style>

<script>
import { today } from '/src/utils/time'
import { useSelectedStore } from "@/stores/selected";
import { useDate } from 'vuetify'

export default {
    setup() {
        const selected_store = useSelectedStore()
        return {
            selected_store,
        }
    },
    props: ["wide"],
    data: () => ({
        dialog: false,
        loading: false,
        date_adapter: useDate(),
    }),
    computed: {
        is_today() {
            return this.date == today()
        },
        text_color() {
            if (this.is_today) {
                return "grey"
            } else {
                return "error"
            }
        },
        date() {
            return this.selected_store.selected_date
        },
        adaptedDate() {
            return this.date_adapter.parseISO(this.date)
        },
    },
    // watch: {
    //     date(d) {
    //         this.dialog = false
    //     },
    // },
    methods: {
        touchDate(new_date) {
            const datestring = this.date_adapter.toISO(new_date)
            this.setDate(datestring)
            this.dialog = false
        },
        setDate(d) {
            this.selected_store.setDate(d)
        },
        tomorrow() {
            const date_object = new Date(this.date)
            date_object.setHours(10)
            date_object.setDate(date_object.getDate() + 1)
            this.setDate(date_object.toISOString().slice(0, 10))
        },
        yesterday() {
            const date_object = new Date(this.date)
            date_object.setHours(10)
            date_object.setDate(date_object.getDate() - 1)
            this.setDate(date_object.toISOString().slice(0, 10))
        }
    },
    mounted() {
        this.setDate(today())
    }
}
</script>