<template>
    <v-card variant="flat" class="my-1 border-thin">
        <v-btn-toggle mandatory color="accent" variant="text" :model-value="designs_mode"
            @update:model-value="dm($event)">
            <v-btn :value="false" width="170">
                <span class="text-truncate" style="width: 160px">
                    {{ t("production") }}
                </span>
            </v-btn>
            <v-btn :value="true" width="170">
                <span class="text-truncate" style="width: 160px">
                    {{ t("design") }}
                </span>
            </v-btn>
        </v-btn-toggle>
    </v-card>
</template>

<script>
import { usePitStoreBasics } from "@/stores/app_common";
import { useViewerStore } from "@/stores/viewer";
export default {
    setup() {
        const store_basics = usePitStoreBasics()
        const viewer_store = useViewerStore()
        return { ...store_basics, viewer_store }
    },
    computed: {
        designs_mode() {
            return this.viewer_store.designs_mode;
        },
    },
    methods: {
        dm(designs_mode) {
            this.viewer_store.setDesignsMode(designs_mode)
        }
    }
}
</script>